module.exports = {
  title: 'Email',
  emailSettings: 'Email Settings',
  emailSettingsDescription: 'Configure basic email sending settings',
  comingSoon:
    'We are working on a new feature! Soon you will be able to send emails to your patients here.',
  tabs: {
    messages: 'Messages',
    settings: 'Settings'
  },
  messages: {
    title: 'Message content',
    subtitle:
      'Define the content of messages that patients will receive from you',
    forPatients: 'For patients',
    forEmployees: 'For employees',
    options: {
      reminders: 'Reminders',
      newReservation: 'New reservation',
      canceledMeeting: 'Cancelled meeting',
      changeDate: 'Change date',
      newOnlineReservation: 'New online reservation',
      employeeNewReservation: 'New reservation',
      settings: 'Settings'
    },
    reminders: {
      patientsWillReceive: 'My patients will receive reminders:',
      title: 'Send email reminder to patient about scheduled appointment',
      subtitle:
        'When enabled, the system will send email reminders about scheduled appointments.',
      content:
        'Dear Patient,\n\nThis is a reminder of your appointment scheduled for {date} at {time} at {practice}.\n\nIf you have any questions, please contact us.\n\nBest regards,\n{practiceName}',
      timing: {
        fixedAdvance: '24 hours before the appointment',
        fixedTime: 'At 8:00 AM one day before the appointment',
        fixedAdvanceLabel: 'With fixed advance',
        fixedAdvanceSuffix: 'before the appointment',
        fixedTimeLabel: 'At a fixed time',
        fixedTimeSuffix: 'before the appointment at'
      },
      days: {
        oneDay: '1 day',
        twoDays: '2 days',
        threeDays: '3 days'
      },
      emailTitle: 'Email title',
      emailContent: 'Email content',
      emailTitlePlaceholder: 'Appointment Reminder',
      emailContentPlaceholder: 'Email content',
      save: 'Save',
      cancel: 'Cancel'
    },
    newReservation: {
      title: 'Send email notification to patient about new appointment',
      subtitle:
        'When enabled, the system will send an email after scheduling a new appointment.',
      emailTitle: 'Email title',
      emailContent: 'Email content',
      emailTitlePlaceholder: 'New appointment',
      emailContentPlaceholder: 'Email content'
    },
    newOnlineReservation: {
      title: 'Send email notification to patient about new online reservation',
      subtitle:
        'When enabled, the system will send an email after patient makes an online reservation.',
      emailTitle: 'Email title',
      emailContent: 'Email content',
      emailTitlePlaceholder: 'New online reservation',
      emailContentPlaceholder: 'Email content'
    },
    canceledMeeting: {
      title: 'Send email notification to patient about cancelled meeting',
      subtitle:
        'When enabled, the system will send an email after a meeting is cancelled.',
      emailTitle: 'Email message title',
      emailContent: 'Email message content',
      emailTitlePlaceholder: 'Meeting Cancellation',
      emailContentPlaceholder: 'Email message content',
      defaultTitle: 'Meeting Cancellation',
      defaultContent:
        'Dear Patient,\n\nWe would like to inform you that your appointment on {date} at {time} at {practiceName} has been cancelled.\n\nIf you have any questions, please contact us.\n\nBest regards,\n{practiceName} Team'
    },
    changeDate: {
      title: 'Send email notification to patient about appointment date change',
      subtitle:
        'When enabled, the system will send an email after an appointment date is changed.',
      emailTitle: 'Email message title',
      emailContent: 'Email message content',
      emailTitlePlaceholder: 'Appointment Date Change',
      emailContentPlaceholder: 'Email message content',
      defaultTitle: 'Appointment Date Change',
      defaultContent:
        'Dear Patient,\n\nWe would like to inform you that your appointment date has been changed. The new appointment is scheduled for {date} at {time} at {practiceName}.\n\nIf you have any questions, please contact us.\n\nBest regards,\n{practiceName} Team'
    },
    employeeSettings: {
      title: 'Email notification settings for employees',
      subtitle:
        'Choose how therapists will receive notifications about new, changed, or canceled appointments in the calendar.',
      newVisits: {
        sectionTitle: 'Notifications about new appointments',
        title: 'Email notification about new appointment',
        description:
          'Employee will receive an email notification when a new appointment is added to their calendar.'
      },
      changedVisits: {
        sectionTitle: 'Notifications about changed appointments',
        title: 'Email notification about changed appointment',
        description:
          'Employee will receive an email notification when an appointment in their calendar is changed.'
      },
      canceledVisits: {
        sectionTitle: 'Notifications about canceled appointments',
        title: 'Email notification about canceled appointment',
        description:
          'Employee will receive an email notification when an appointment in their calendar is canceled.'
      }
    },
    common: {
      save: 'Save',
      cancel: 'Cancel'
    }
  },
  settings: {
    title: 'Email sending settings',
    subtitle: 'Configure basic email sending settings',
    replyToEmail: {
      label: 'Reply-To email address',
      description:
        'This email address will be used as the address in the "Reply-To" function. By default, the system address is used.',
      placeholder: 'e.g. contact@yourpractice.com'
    },
    senderName: {
      label: 'Sender name',
      description:
        'This name will be displayed as the sender of the email message.',
      placeholder: 'e.g. Medical Practice'
    },
    save: 'Save',
    cancel: 'Cancel'
  },
  description: 'Manage email settings'
}
